import AssetDetails from '@/components/Asset/AssetDetails/AssetDetails.vue'
import AssetBreadcrumbs from '@/components/Asset/AssetBreadcrumbs/AssetBreadcrumbs.vue'

export default {
  props: ['id'],
  components: {
    AssetBreadcrumbs,
    AssetDetails
  },
  data() {
    return {
      asset: {}
    }
  },
  methods: {
    tagClicked(tag) {
      this.$router.push({
        name: 'list-assets',
        params: { type: 'allAssets', searchProp: tag }
      })
    },
    updateAsset(asset) {
      this.asset = asset
    }
  },
  created() {
    if (_.isNaN(parseInt(this.id)) || parseInt(this.id) > 2147483647) {
      this.renderError(404)
    }
  }
}
