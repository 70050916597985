export default {
  props: {
    name: String
  },
  computed: {
    type() {
      return this.$route.params.type
    },
    title() {
      return _.startCase(this.type)
    },
    breadcrumbItems() {
      let text
      if (this.$route.name === 'show-asset') {
        text = `${this.name}`
      } else if (this.$route.name === 'edit-asset') {
        text = `Edit ${this.name}`
      } else if (this.$route.name === 'create-asset') {
        text = 'New'
      }
      return [
        {
          text: this.title,
          disabled: false,
          to: { name: 'list-assets', params: { type: this.type } },
          exact: true
        },
        {
          text
        }
      ]
    }
  }
}
